<template>
  <div style="margin-bottom: 90px;">
    <van-image
        fit="contain"
        :src="require('@/assets/jiaofei.jpeg')"
    />
    <van-form>
      <van-radio-group v-model="form.productId">
        <van-cell-group title="交费项目">
          <van-cell v-for="(v,k) in productList" :key="k" :title="v.title" clickable :label="v.remark" @click="onProductChange(v)">
            <template #right-icon>
              <van-radio :name="v.id.toString()" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-cell-group title="学生信息">
        <van-field
            is-link
            readonly
            name="picker"
            label="学校"
            placeholder="点击选择学校"
            @click="onShowPicker('school',schoolList)"
            v-model="form.school.title"
        />
        <van-field
            is-link
            readonly
            name="picker"
            label="年级"
            placeholder="点击选择年级"
            v-model="form.grade.title"
            @click="onShowPicker('grade',gradeList)"
        />
        <van-field
            is-link
            readonly
            name="picker"
            label="班级"
            placeholder="点击选择班级"
            v-model="form.classes.title"
            @click="onShowPicker('classes',classesList)"
        />
        <van-field
            name="pattern"
            label="姓名"
            v-model="form.name"
            placeholder="请填写学生姓名"
        />
        <van-popup position="bottom" v-model:show="showPicker">
          <van-picker
              :columns="columns"
              @cancel="showPicker = false"
              :columns-field-names="fieldName"
              @confirm="onConfirm"
          />
        </van-popup>
      </van-cell-group>
      <van-radio-group v-model="form.payType">
        <van-cell-group title="支付方式">
          <van-cell title="支付宝" clickable @click="form.payType = 'AliPay'">
            <template #icon>
              <van-image src="/images/alipay.svg" width="24" style="margin-right: 10px;"/>
            </template>
            <template #right-icon>
              <van-radio name="AliPay"/>
            </template>
          </van-cell>
          <van-cell title="微信支付" clickable @click="form.payType = 'WeChat'">
            <template #icon>
              <van-image src="/images/wechat.svg" width="24" style="margin-right: 10px;"/>
            </template>
            <template #right-icon>
              <van-radio name="WeChat"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-form>
    <van-submit-bar :price="productDetail.price*100" tip-icon="info-o" tip="请仔细核对学生信息,填写错误无法修改"
                    button-text="确认提交" @click="onSubmit" :loading="loading"/>
  </div>
</template>
<script setup>
import {getClasses, getGarde, getOpenId, getSchool} from "@/api/common";
import {onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {detail, list} from "@/api/product";
import {create} from "@/api/orders";
import {showFailToast} from "vant";
import {getCache, isWeiXin, setCache} from "@/utils/common";
import settings from "@/config/settings";

const form = reactive({
  school: {},
  grade: {},
  classes: {},
  payType: 'AliPay',
  productId: '',
  name: null,
  ext: null,
})
const schoolList = ref([]);
const gradeList = ref([]);
const classesList = ref([]);
const productList = ref([]);
const showPicker = ref(false);
const loading = ref(false);
const columns = ref([]);
const pickerType = ref(null);
const route = useRoute();
const router = useRouter();
const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${settings.WXAPPID}&redirect_uri=${encodeURIComponent(location.href)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
console.log(authUrl)
const productDetail = reactive({
  id: null,
  title: null,
  remark: null,
  price: null,
})
const fieldName = {
  text: 'title',
  value: 'id',
}
const onShowPicker = (type, col) => {
  columns.value = col
  pickerType.value = type
  showPicker.value = true
}
const onConfirm = (value) => {
  if (value.selectedValues.length === 0) {
    showPicker.value = false
    return false;
  }
  switch (pickerType.value) {
    case 'school':
      form.school = value.selectedOptions[0]
      break;
    case 'grade':
      form.grade = value.selectedOptions[0]
      break;
    case 'classes':
      form.classes = value.selectedOptions[0]
      break;
  }
  showPicker.value = false
}

onMounted(() => {
  getSchool().then(({data}) => {
    schoolList.value = data.data
  })
  getProductList();
  form.productId = route.query['p']
  detail(form.productId).then(({data}) => {
    productDetail.id = data.data.id
    productDetail.title = data.data.title
    productDetail.remark = data.data.remark
    productDetail.price = data.data.price
  })
  if (isWeiXin()) {
    if (route.query['code']) {
      getOpenId(route.query['code']).then(({data}) => {
        if (data.code === "00000") {
          setCache('openid', data.data)
        } else {
          window.location.href = authUrl
        }
      })
    } else {
      if (!getCache('openid')) {
        window.location.href = authUrl
      }
    }
  }
})
const getProductList = () => {
  list("").then(({data}) => {
    productList.value = data.data
  })
}
const onProductChange = (product)=>{
  productDetail.id=product.id
  productDetail.price=product.price
  form.productId=product.id.toString()
}

const onSubmit = () => {
  let formData = {
    productId: form.productId,
    schoolId: form.school.id,
    schoolGradeId: form.grade.id,
    classesId: form.classes.id,
    name: form.name,
    payType: form.payType,
  }
  if (isWeiXin()) {
    formData['ext'] = getCache('openid')
  }
  if (formData.productId === null || formData.productId === undefined) {
    showFailToast('请选择交费项目');
    return false;
  } else if (formData.schoolId === null || formData.schoolId === undefined) {
    showFailToast('请选择学校');
    return false;
  } else if (formData.schoolGradeId === null || formData.schoolGradeId === undefined) {
    showFailToast('请选择年级');
    return false;
  } else if (formData.classesId === null || formData.classesId === undefined) {
    showFailToast('请选择班级');
    return false;
  } else if (formData.name === null || formData.name === undefined || formData.name === '') {
    showFailToast('请填写姓名');
    return false;
  }
  if (formData.payType === 'WeChat') {
    if (!isWeiXin()) {
      showFailToast('请在微信中打开');
      return false;
    }
  }
  loading.value = true
  create(formData).then(({data}) => {
    if (data.code === '00000') {
      router.push({
        path: '/pay/',
        query: {o: data.data.orderNo}
      })
    } else {
      showFailToast('创建支付失败');
    }
  })
}
watch(() => form.school, (value) => {
  //学校选择变化
  form.grade = {}
  form.classes = {}
  getGarde(value.id).then(({data}) => {
    gradeList.value = data.data
  })
})
watch(() => form.grade, (value) => {
  //学校选择变化
  form.classes = {}
  getClasses(value.id).then(({data}) => {
    classesList.value = data.data
  })
})
</script>

<style scoped>

</style>
