import api from "@/utils/api";

export function getSchool(){
    return api.get('/school/list')
}
export function getGarde(schoolId){
    return api.get('/school/grade',{params:{schoolId:schoolId}})
}

export function getClasses(gradeId){
    return api.get('/school/classes',{params:{schoolGradeId:gradeId}})
}

export function getOpenId(code){
    return api.get('/pay/openid',{params:{code}})
}
export function wxPaySign(appId){

}
